//Set and Unset Admin
export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";
export  const SIGNUP_ADMIN ="SIGNUP_ADMIN"

//Login Error
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

//Update Profile and name
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_NAME = "UPDATE_PROFILE_NAME";

//Send Email
export const SEND_EMAIL = "SEND_EMAIL";

//toast
export const OPEN_ADMIN_TOAST = "OPEN_ADMIN_TOAST";
export const CLOSE_ADMIN_TOAST = "CLOSE_ADMIN_TOAST";


// forgot password 
export const FORGOT_PASSWORD ="FORGOT_PASSWORD"
