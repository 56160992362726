//get setting
export const GET_SETTING = "GET_SETTING";

//update setting
export const UPDATE_SETTING = "UPDATE_SETTING";

// switch Accept
export const SWITCH_ACCEPT = "SWITCH_ACCEPT";

//toast
export const OPEN_SETTING_TOAST = "OPEN_SETTING_TOAST";
export const CLOSE_SETTING_TOAST = "CLOSE_SETTING_TOAST";
