//Get Region
export const GET_SEASON = "GET_SEASON";

//Insert Open Dialog
export const OPEN_SEASON_DIALOG = "OPEN_SEASON_DIALOG";

//Close Dialog
export const CLOSE_SEASON_DIALOG = "CLOSE_SEASON_DIALOG";

//Insert Season
export const INSERT_SEASON = "INSERT_SEASON";

// export Update Season
export const UPDATE_SEASON = "UPDATE_SEASON";

// delete season 
export const DELETE_SEASON = "DELETE_SEASON";