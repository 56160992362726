//Get User
export const GET_USER = "GET_USER";

//Get User Details
export const USER_DETAILS = "USER_DETAILS";

//Get history
export const GET_HISTORY = "GET_HISTORY";

//block Switch
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";
