/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable no-sequences */
/* @license
morris.js v0.5.0
Copyright 2014 Olly Smith All rights reserved.
Licensed under the BSD-2-Clause License.
*/
import jQuery from "jquery";

(function () {
  var $,
    Morris,
    minutesSpecHelper,
    secondsSpecHelper,
    __slice = [].slice,
    __bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    },
    __hasProp = {}.hasOwnProperty,
    __extends = function (child, parent) {
      for (var key in parent) {
        if (__hasProp.call(parent, key)) child[key] = parent[key];
      }
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
    __indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  Morris = window.Morris = {};

  $ = jQuery;

  Morris.EventEmitter = (function () {
    function EventEmitter() {}

    EventEmitter.prototype.on = function (name, handler) {
      if (this.handlers == null) {
        this.handlers = {};
      }
      if (this.handlers[name] == null) {
        this.handlers[name] = [];
      }
      this.handlers[name].push(handler);
      return this;
    };

    EventEmitter.prototype.fire = function () {
      var args, handler, name, _i, _len, _ref, _results;
      (name = arguments[0]),
        (args = 2 <= arguments.length ? __slice.call(arguments, 1) : []);
      if (this.handlers != null && this.handlers[name] != null) {
        _ref = this.handlers[name];
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          handler = _ref[_i];
          _results.push(handler.apply(null, args));
        }
        return _results;
      }
    };

    return EventEmitter;
  })();

  Morris.commas = function (num) {
    var absnum, intnum, ret, strabsnum;
    if (num != null) {
      ret = num < 0 ? "-" : "";
      absnum = Math.abs(num);
      intnum = Math.floor(absnum).toFixed(0);
      ret += intnum.replace(/(?=(?:\d{3})+$)(?!^)/g, ",");
      strabsnum = absnum.toString();
      if (strabsnum.length > intnum.length) {
        ret += strabsnum.slice(intnum.length);
      }
      return ret;
    } else {
      return "-";
    }
  };

  Morris.pad2 = function (number) {
    return (number < 10 ? "0" : "") + number;
  };

  Morris.Grid = (function (_super) {
    __extends(Grid, _super);

    function Grid(options) {
      this.resizeHandler = __bind(this.resizeHandler, this);
      var _this = this;
      if (typeof options.element === "string") {
        this.el = $(document.getElementById(options.element));
      } else {
        this.el = $(options.element);
      }
      if (this.el == null || this.el.length === 0) {
        throw new Error("Graph container element not found");
      }
      if (this.el.css("position") === "static") {
        this.el.css("position", "relative");
      }
      this.options = $.extend(
        {},
        this.gridDefaults,
        this.defaults || {},
        options
      );
      if (typeof this.options.units === "string") {
        this.options.postUnits = options.units;
      }
      this.raphael = new Raphael(this.el[0]);
      this.elementWidth = null;
      this.elementHeight = null;
      this.dirty = false;
      this.selectFrom = null;
      if (this.init) {
        this.init();
      }
      this.setData(this.options.data);
      this.el.bind("mousemove", function (evt) {
        var left, offset, right, width, x;
        offset = _this.el.offset();
        x = evt.pageX - offset.left;
        if (_this.selectFrom) {
          left = _this.data[_this.hitTest(Math.min(x, _this.selectFrom))]._x;
          right = _this.data[_this.hitTest(Math.max(x, _this.selectFrom))]._x;
          width = right - left;
          return _this.selectionRect.attr({
            x: left,
            width: width,
          });
        } else {
          return _this.fire("hovermove", x, evt.pageY - offset.top);
        }
      });
      this.el.bind("mouseleave", function (evt) {
        if (_this.selectFrom) {
          _this.selectionRect.hide();
          _this.selectFrom = null;
        }
        return _this.fire("hoverout");
      });
      this.el.bind("touchstart touchmove touchend", function (evt) {
        var offset, touch;
        touch =
          evt.originalEvent.touches[0] || evt.originalEvent.changedTouches[0];
        offset = _this.el.offset();
        return _this.fire(
          "hovermove",
          touch.pageX - offset.left,
          touch.pageY - offset.top
        );
      });
      this.el.bind("click", function (evt) {
        var offset;
        offset = _this.el.offset();
        return _this.fire(
          "gridclick",
          evt.pageX - offset.left,
          evt.pageY - offset.top
        );
      });
      if (this.options.rangeSelect) {
        this.selectionRect = this.raphael
          .rect(0, 0, 0, this.el.innerHeight())
          .attr({
            fill: this.options.rangeSelectColor,
            stroke: false,
          })
          .toBack()
          .hide();
        this.el.bind("mousedown", function (evt) {
          var offset;
          offset = _this.el.offset();
          return _this.startRange(evt.pageX - offset.left);
        });
        this.el.bind("mouseup", function (evt) {
          var offset;
          offset = _this.el.offset();
          _this.endRange(evt.pageX - offset.left);
          return _this.fire(
            "hovermove",
            evt.pageX - offset.left,
            evt.pageY - offset.top
          );
        });
      }
      if (this.options.resize) {
        $(window).bind("resize", function (evt) {
          if (_this.timeoutId != null) {
            window.clearTimeout(_this.timeoutId);
          }
          return (_this.timeoutId = window.setTimeout(
            _this.resizeHandler,
            100
          ));
        });
      }
      this.el.css("-webkit-tap-highlight-color", "rgba(0,0,0,0)");
      if (this.postInit) {
        this.postInit();
      }
    }

    Grid.prototype.gridDefaults = {
      dateFormat: null,
      axes: true,
      grid: true,
      gridLineColor: "#aaa",
      gridStrokeWidth: 0.5,
      gridTextColor: "#888",
      gridTextSize: 12,
      gridTextFamily: "sans-serif",
      gridTextWeight: "normal",
      hideHover: false,
      yLabelFormat: null,
      xLabelAngle: 0,
      numLines: 5,
      padding: 25,
      parseTime: true,
      postUnits: "",
      preUnits: "",
      ymax: "auto",
      ymin: "auto 0",
      goals: [],
      goalStrokeWidth: 1.0,
      goalLineColors: ["#666633", "#999966", "#cc6666", "#663333"],
      events: [],
      eventStrokeWidth: 1.0,
      eventLineColors: ["#005a04", "#ccffbb", "#3a5f0b", "#005502"],
      rangeSelect: null,
      rangeSelectColor: "#eef",
      resize: false,
    };

    Grid.prototype.setData = function (data, redraw) {
      var e,
        idx,
        index,
        maxGoal,
        minGoal,
        ret,
        row,
        step,
        total,
        y,
        ykey,
        ymax,
        ymin,
        yval,
        _ref;
      if (redraw == null) {
        redraw = true;
      }
      this.options.data = data;
      if (data == null || data.length === 0) {
        this.data = [];
        this.raphael.clear();
        if (this.hover != null) {
          this.hover.hide();
        }
        return;
      }
      ymax = this.cumulative ? 0 : null;
      ymin = this.cumulative ? 0 : null;
      if (this.options.goals.length > 0) {
        minGoal = Math.min.apply(Math, this.options.goals);
        maxGoal = Math.max.apply(Math, this.options.goals);
        ymin = ymin != null ? Math.min(ymin, minGoal) : minGoal;
        ymax = ymax != null ? Math.max(ymax, maxGoal) : maxGoal;
      }
      this.data = function () {
        var _i, _len, _results;
        _results = [];
        for (index = _i = 0, _len = data.length; _i < _len; index = ++_i) {
          row = data[index];
          ret = {
            src: row,
          };
          ret.label = row[this.options.xkey];
          if (this.options.parseTime) {
            ret.x = Morris.parseDate(ret.label);
            if (this.options.dateFormat) {
              ret.label = this.options.dateFormat(ret.x);
            } else if (typeof ret.label === "number") {
              ret.label = new Date(ret.label).toString();
            }
          } else {
            ret.x = index;
            if (this.options.xLabelFormat) {
              ret.label = this.options.xLabelFormat(ret);
            }
          }
          total = 0;
          ret.y = function () {
            var _j, _len1, _ref, _results1;
            _ref = this.options.ykeys;
            _results1 = [];
            for (idx = _j = 0, _len1 = _ref.length; _j < _len1; idx = ++_j) {
              ykey = _ref[idx];
              yval = row[ykey];
              if (typeof yval === "string") {
                yval = parseFloat(yval);
              }
              if (yval != null && typeof yval !== "number") {
                yval = null;
              }
              if (yval != null) {
                if (this.cumulative) {
                  total += yval;
                } else {
                  if (ymax != null) {
                    ymax = Math.max(yval, ymax);
                    ymin = Math.min(yval, ymin);
                  } else {
                    ymax = ymin = yval;
                  }
                }
              }
              if (this.cumulative && total != null) {
                ymax = Math.max(total, ymax);
                ymin = Math.min(total, ymin);
              }
              _results1.push(yval);
            }
            return _results1;
          }.call(this);
          _results.push(ret);
        }
        return _results;
      }.call(this);
      if (this.options.parseTime) {
        this.data = this.data.sort(function (a, b) {
          return (a.x > b.x) - (b.x > a.x);
        });
      }
      this.xmin = this.data[0].x;
      this.xmax = this.data[this.data.length - 1].x;
      this.events = [];
      if (this.options.events.length > 0) {
        if (this.options.parseTime) {
          this.events = function () {
            var _i, _len, _ref, _results;
            _ref = this.options.events;
            _results = [];
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
              e = _ref[_i];
              _results.push(Morris.parseDate(e));
            }
            return _results;
          }.call(this);
        } else {
          this.events = this.options.events;
        }
        this.xmax = Math.max(this.xmax, Math.max.apply(Math, this.events));
        this.xmin = Math.min(this.xmin, Math.min.apply(Math, this.events));
      }
      if (this.xmin === this.xmax) {
        this.xmin -= 1;
        this.xmax += 1;
      }
      this.ymin = this.yboundary("min", ymin);
      this.ymax = this.yboundary("max", ymax);
      if (this.ymin === this.ymax) {
        if (ymin) {
          this.ymin -= 1;
        }
        this.ymax += 1;
      }
      if (
        (_ref = this.options.axes) === true ||
        _ref === "both" ||
        _ref === "y" ||
        this.options.grid === true
      ) {
        if (
          this.options.ymax === this.gridDefaults.ymax &&
          this.options.ymin === this.gridDefaults.ymin
        ) {
          this.grid = this.autoGridLines(
            this.ymin,
            this.ymax,
            this.options.numLines
          );
          this.ymin = Math.min(this.ymin, this.grid[0]);
          this.ymax = Math.max(this.ymax, this.grid[this.grid.length - 1]);
        } else {
          step = (this.ymax - this.ymin) / (this.options.numLines - 1);
          this.grid = function () {
            var _i, _ref1, _ref2, _results;
            _results = [];
            for (
              y = _i = _ref1 = this.ymin, _ref2 = this.ymax;
              step > 0 ? _i <= _ref2 : _i >= _ref2;
              y = _i += step
            ) {
              _results.push(y);
            }
            return _results;
          }.call(this);
        }
      }
      this.dirty = true;
      if (redraw) {
        return this.redraw();
      }
    };

    Grid.prototype.yboundary = function (boundaryType, currentValue) {
      var boundaryOption, suggestedValue;
      boundaryOption = this.options["y" + boundaryType];
      if (typeof boundaryOption === "string") {
        if (boundaryOption.slice(0, 4) === "auto") {
          if (boundaryOption.length > 5) {
            suggestedValue = parseInt(boundaryOption.slice(5), 10);
            if (currentValue == null) {
              return suggestedValue;
            }
            return Math[boundaryType](currentValue, suggestedValue);
          } else {
            if (currentValue != null) {
              return currentValue;
            } else {
              return 0;
            }
          }
        } else {
          return parseInt(boundaryOption, 10);
        }
      } else {
        return boundaryOption;
      }
    };

    Grid.prototype.autoGridLines = function (ymin, ymax, nlines) {
      var gmax, gmin, grid, smag, span, step, unit, y, ymag;
      span = ymax - ymin;
      ymag = Math.floor(Math.log(span) / Math.log(10));
      unit = Math.pow(10, ymag);
      gmin = Math.floor(ymin / unit) * unit;
      gmax = Math.ceil(ymax / unit) * unit;
      step = (gmax - gmin) / (nlines - 1);
      if (unit === 1 && step > 1 && Math.ceil(step) !== step) {
        step = Math.ceil(step);
        gmax = gmin + step * (nlines - 1);
      }
      if (gmin < 0 && gmax > 0) {
        gmin = Math.floor(ymin / step) * step;
        gmax = Math.ceil(ymax / step) * step;
      }
      if (step < 1) {
        smag = Math.floor(Math.log(step) / Math.log(10));
        grid = (function () {
          var _i, _results;
          _results = [];
          for (
            y = _i = gmin;
            step > 0 ? _i <= gmax : _i >= gmax;
            y = _i += step
          ) {
            _results.push(parseFloat(y.toFixed(1 - smag)));
          }
          return _results;
        })();
      } else {
        grid = (function () {
          var _i, _results;
          _results = [];
          for (
            y = _i = gmin;
            step > 0 ? _i <= gmax : _i >= gmax;
            y = _i += step
          ) {
            _results.push(y);
          }
          return _results;
        })();
      }
      return grid;
    };

    Grid.prototype._calc = function () {
      var bottomOffsets, gridLine, h, i, w, yLabelWidths, _ref, _ref1;
      w = this.el.width();
      h = this.el.height();
      if (this.elementWidth !== w || this.elementHeight !== h || this.dirty) {
        this.elementWidth = w;
        this.elementHeight = h;
        this.dirty = false;
        this.left = this.options.padding;
        this.right = this.elementWidth - this.options.padding;
        this.top = this.options.padding;
        this.bottom = this.elementHeight - this.options.padding;
        if (
          (_ref = this.options.axes) === true ||
          _ref === "both" ||
          _ref === "y"
        ) {
          yLabelWidths = function () {
            var _i, _len, _ref1, _results;
            _ref1 = this.grid;
            _results = [];
            for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
              gridLine = _ref1[_i];
              _results.push(this.measureText(this.yAxisFormat(gridLine)).width);
            }
            return _results;
          }.call(this);
          this.left += Math.max.apply(Math, yLabelWidths);
        }
        if (
          (_ref1 = this.options.axes) === true ||
          _ref1 === "both" ||
          _ref1 === "x"
        ) {
          bottomOffsets = function () {
            var _i, _ref2, _results;
            _results = [];
            for (
              i = _i = 0, _ref2 = this.data.length;
              0 <= _ref2 ? _i < _ref2 : _i > _ref2;
              i = 0 <= _ref2 ? ++_i : --_i
            ) {
              _results.push(
                this.measureText(this.data[i].text, -this.options.xLabelAngle)
                  .height
              );
            }
            return _results;
          }.call(this);
          this.bottom -= Math.max.apply(Math, bottomOffsets);
        }
        this.width = Math.max(1, this.right - this.left);
        this.height = Math.max(1, this.bottom - this.top);
        this.dx = this.width / (this.xmax - this.xmin);
        this.dy = this.height / (this.ymax - this.ymin);
        if (this.calc) {
          return this.calc();
        }
      }
    };

    Grid.prototype.transY = function (y) {
      return this.bottom - (y - this.ymin) * this.dy;
    };

    Grid.prototype.transX = function (x) {
      if (this.data.length === 1) {
        return (this.left + this.right) / 2;
      } else {
        return this.left + (x - this.xmin) * this.dx;
      }
    };

    Grid.prototype.redraw = function () {
      this.raphael.clear();
      this._calc();
      this.drawGrid();
      this.drawGoals();
      this.drawEvents();
      if (this.draw) {
        return this.draw();
      }
    };

    Grid.prototype.measureText = function (text, angle) {
      var ret, tt;
      if (angle == null) {
        angle = 0;
      }
      tt = this.raphael
        .text(100, 100, text)
        .attr("font-size", this.options.gridTextSize)
        .attr("font-family", this.options.gridTextFamily)
        .attr("font-weight", this.options.gridTextWeight)
        .rotate(angle);
      ret = tt.getBBox();
      tt.remove();
      return ret;
    };

    Grid.prototype.yAxisFormat = function (label) {
      return this.yLabelFormat(label);
    };

    Grid.prototype.yLabelFormat = function (label) {
      if (typeof this.options.yLabelFormat === "function") {
        return this.options.yLabelFormat(label);
      } else {
        return (
          "" +
          this.options.preUnits +
          Morris.commas(label) +
          this.options.postUnits
        );
      }
    };

    Grid.prototype.drawGrid = function () {
      var lineY, y, _i, _len, _ref, _ref1, _ref2, _results;
      if (
        this.options.grid === false &&
        (_ref = this.options.axes) !== true &&
        _ref !== "both" &&
        _ref !== "y"
      ) {
        return;
      }
      _ref1 = this.grid;
      _results = [];
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        lineY = _ref1[_i];
        y = this.transY(lineY);
        if (
          (_ref2 = this.options.axes) === true ||
          _ref2 === "both" ||
          _ref2 === "y"
        ) {
          this.drawYAxisLabel(
            this.left - this.options.padding / 2,
            y,
            this.yAxisFormat(lineY)
          );
        }
        if (this.options.grid) {
          _results.push(
            this.drawGridLine(
              "M" + this.left + "," + y + "H" + (this.left + this.width)
            )
          );
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    };

    Grid.prototype.drawGoals = function () {
      var color, goal, i, _i, _len, _ref, _results;
      _ref = this.options.goals;
      _results = [];
      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        goal = _ref[i];
        color =
          this.options.goalLineColors[i % this.options.goalLineColors.length];
        _results.push(this.drawGoal(goal, color));
      }
      return _results;
    };

    Grid.prototype.drawEvents = function () {
      var color, event, i, _i, _len, _ref, _results;
      _ref = this.events;
      _results = [];
      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        event = _ref[i];
        color =
          this.options.eventLineColors[i % this.options.eventLineColors.length];
        _results.push(this.drawEvent(event, color));
      }
      return _results;
    };

    Grid.prototype.drawGoal = function (goal, color) {
      return this.raphael
        .path("M" + this.left + "," + this.transY(goal) + "H" + this.right)
        .attr("stroke", color)
        .attr("stroke-width", this.options.goalStrokeWidth);
    };

    Grid.prototype.drawEvent = function (event, color) {
      return this.raphael
        .path("M" + this.transX(event) + "," + this.bottom + "V" + this.top)
        .attr("stroke", color)
        .attr("stroke-width", this.options.eventStrokeWidth);
    };

    Grid.prototype.drawYAxisLabel = function (xPos, yPos, text) {
      return this.raphael
        .text(xPos, yPos, text)
        .attr("font-size", this.options.gridTextSize)
        .attr("font-family", this.options.gridTextFamily)
        .attr("font-weight", this.options.gridTextWeight)
        .attr("fill", this.options.gridTextColor)
        .attr("text-anchor", "end");
    };

    Grid.prototype.drawGridLine = function (path) {
      return this.raphael
        .path(path)
        .attr("stroke", this.options.gridLineColor)
        .attr("stroke-width", this.options.gridStrokeWidth);
    };

    Grid.prototype.startRange = function (x) {
      this.hover.hide();
      this.selectFrom = x;
      return this.selectionRect
        .attr({
          x: x,
          width: 0,
        })
        .show();
    };

    Grid.prototype.endRange = function (x) {
      var end, start;
      if (this.selectFrom) {
        start = Math.min(this.selectFrom, x);
        end = Math.max(this.selectFrom, x);
        this.options.rangeSelect.call(this.el, {
          start: this.data[this.hitTest(start)].x,
          end: this.data[this.hitTest(end)].x,
        });
        return (this.selectFrom = null);
      }
    };

    Grid.prototype.resizeHandler = function () {
      this.timeoutId = null;
      this.raphael.setSize(this.el.width(), this.el.height());
      return this.redraw();
    };

    return Grid;
  })(Morris.EventEmitter);

  Morris.parseDate = function (date) {
    var isecs, m, msecs, n, o, offsetmins, p, q, r, ret, secs;
    if (typeof date === "number") {
      return date;
    }
    m = date.match(/^(\d+) Q(\d)$/);
    n = date.match(/^(\d+)-(\d+)$/);
    o = date.match(/^(\d+)-(\d+)-(\d+)$/);
    p = date.match(/^(\d+) W(\d+)$/);
    q = date.match(
      /^(\d+)-(\d+)-(\d+)[ T](\d+):(\d+)(Z|([+-])(\d\d):?(\d\d))?$/
    );
    r = date.match(
      /^(\d+)-(\d+)-(\d+)[ T](\d+):(\d+):(\d+(\.\d+)?)(Z|([+-])(\d\d):?(\d\d))?$/
    );
    if (m) {
      return new Date(
        parseInt(m[1], 10),
        parseInt(m[2], 10) * 3 - 1,
        1
      ).getTime();
    } else if (n) {
      return new Date(parseInt(n[1], 10), parseInt(n[2], 10) - 1, 1).getTime();
    } else if (o) {
      return new Date(
        parseInt(o[1], 10),
        parseInt(o[2], 10) - 1,
        parseInt(o[3], 10)
      ).getTime();
    } else if (p) {
      ret = new Date(parseInt(p[1], 10), 0, 1);
      if (ret.getDay() !== 4) {
        ret.setMonth(0, 1 + ((4 - ret.getDay() + 7) % 7));
      }
      return ret.getTime() + parseInt(p[2], 10) * 604800000;
    } else if (q) {
      if (!q[6]) {
        return new Date(
          parseInt(q[1], 10),
          parseInt(q[2], 10) - 1,
          parseInt(q[3], 10),
          parseInt(q[4], 10),
          parseInt(q[5], 10)
        ).getTime();
      } else {
        offsetmins = 0;
        if (q[6] !== "Z") {
          offsetmins = parseInt(q[8], 10) * 60 + parseInt(q[9], 10);
          if (q[7] === "+") {
            offsetmins = 0 - offsetmins;
          }
        }
        return Date.UTC(
          parseInt(q[1], 10),
          parseInt(q[2], 10) - 1,
          parseInt(q[3], 10),
          parseInt(q[4], 10),
          parseInt(q[5], 10) + offsetmins
        );
      }
    } else if (r) {
      secs = parseFloat(r[6]);
      isecs = Math.floor(secs);
      msecs = Math.round((secs - isecs) * 1000);
      if (!r[8]) {
        return new Date(
          parseInt(r[1], 10),
          parseInt(r[2], 10) - 1,
          parseInt(r[3], 10),
          parseInt(r[4], 10),
          parseInt(r[5], 10),
          isecs,
          msecs
        ).getTime();
      } else {
        offsetmins = 0;
        if (r[8] !== "Z") {
          offsetmins = parseInt(r[10], 10) * 60 + parseInt(r[11], 10);
          if (r[9] === "+") {
            offsetmins = 0 - offsetmins;
          }
        }
        return Date.UTC(
          parseInt(r[1], 10),
          parseInt(r[2], 10) - 1,
          parseInt(r[3], 10),
          parseInt(r[4], 10),
          parseInt(r[5], 10) + offsetmins,
          isecs,
          msecs
        );
      }
    } else {
      return new Date(parseInt(date, 10), 0, 1).getTime();
    }
  };

  Morris.Hover = (function () {
    Hover.defaults = {
      class: "morris-hover morris-default-style",
    };

    function Hover(options) {
      if (options == null) {
        options = {};
      }
      this.options = $.extend({}, Morris.Hover.defaults, options);
      this.el = $("<div class='" + this.options["class"] + "'></div>");
      this.el.hide();
      this.options.parent.append(this.el);
    }

    Hover.prototype.update = function (html, x, y) {
      if (!html) {
        return this.hide();
      } else {
        this.html(html);
        this.show();
        return this.moveTo(x, y);
      }
    };

    Hover.prototype.html = function (content) {
      return this.el.html(content);
    };

    Hover.prototype.moveTo = function (x, y) {
      var hoverHeight, hoverWidth, left, parentHeight, parentWidth, top;
      parentWidth = this.options.parent.innerWidth();
      parentHeight = this.options.parent.innerHeight();
      hoverWidth = this.el.outerWidth();
      hoverHeight = this.el.outerHeight();
      left = Math.min(
        Math.max(0, x - hoverWidth / 2),
        parentWidth - hoverWidth
      );
      if (y != null) {
        top = y - hoverHeight - 10;
        if (top < 0) {
          top = y + 10;
          if (top + hoverHeight > parentHeight) {
            top = parentHeight / 2 - hoverHeight / 2;
          }
        }
      } else {
        top = parentHeight / 2 - hoverHeight / 2;
      }
      return this.el.css({
        left: left + "px",
        top: parseInt(top) + "px",
      });
    };

    Hover.prototype.show = function () {
      return this.el.show();
    };

    Hover.prototype.hide = function () {
      return this.el.hide();
    };

    return Hover;
  })();

  Morris.Line = (function (_super) {
    __extends(Line, _super);

    function Line(options) {
      this.hilight = __bind(this.hilight, this);
      this.onHoverOut = __bind(this.onHoverOut, this);
      this.onHoverMove = __bind(this.onHoverMove, this);
      this.onGridClick = __bind(this.onGridClick, this);
      if (!(this instanceof Morris.Line)) {
        return new Morris.Line(options);
      }
      Line.__super__.constructor.call(this, options);
    }

    Line.prototype.init = function () {
      if (this.options.hideHover !== "always") {
        this.hover = new Morris.Hover({
          parent: this.el,
        });
        this.on("hovermove", this.onHoverMove);
        this.on("hoverout", this.onHoverOut);
        return this.on("gridclick", this.onGridClick);
      }
    };

    Line.prototype.defaults = {
      lineWidth: 3,
      pointSize: 4,
      lineColors: [
        "#0b62a4",
        "#7A92A3",
        "#4da74d",
        "#afd8f8",
        "#edc240",
        "#cb4b4b",
        "#9440ed",
      ],
      pointStrokeWidths: [1],
      pointStrokeColors: ["#ffffff"],
      pointFillColors: [],
      smooth: true,
      xLabels: "auto",
      xLabelFormat: null,
      xLabelMargin: 24,
      hideHover: false,
    };

    Line.prototype.calc = function () {
      this.calcPoints();
      return this.generatePaths();
    };

    Line.prototype.calcPoints = function () {
      var row, y, _i, _len, _ref, _results;
      _ref = this.data;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        row = _ref[_i];
        row._x = this.transX(row.x);
        row._y = function () {
          var _j, _len1, _ref1, _results1;
          _ref1 = row.y;
          _results1 = [];
          for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
            y = _ref1[_j];
            if (y != null) {
              _results1.push(this.transY(y));
            } else {
              _results1.push(y);
            }
          }
          return _results1;
        }.call(this);
        _results.push(
          (row._ymax = Math.min.apply(
            Math,
            [this.bottom].concat(
              (function () {
                var _j, _len1, _ref1, _results1;
                _ref1 = row._y;
                _results1 = [];
                for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
                  y = _ref1[_j];
                  if (y != null) {
                    _results1.push(y);
                  }
                }
                return _results1;
              })()
            )
          ))
        );
      }
      return _results;
    };

    Line.prototype.hitTest = function (x) {
      var index, r, _i, _len, _ref;
      if (this.data.length === 0) {
        return null;
      }
      _ref = this.data.slice(1);
      for (index = _i = 0, _len = _ref.length; _i < _len; index = ++_i) {
        r = _ref[index];
        if (x < (r._x + this.data[index]._x) / 2) {
          break;
        }
      }
      return index;
    };

    Line.prototype.onGridClick = function (x, y) {
      var index;
      index = this.hitTest(x);
      return this.fire("click", index, this.data[index].src, x, y);
    };

    Line.prototype.onHoverMove = function (x, y) {
      var index;
      index = this.hitTest(x);
      return this.displayHoverForRow(index);
    };

    Line.prototype.onHoverOut = function () {
      if (this.options.hideHover !== false) {
        return this.displayHoverForRow(null);
      }
    };

    Line.prototype.displayHoverForRow = function (index) {
      var _ref;
      if (index != null) {
        (_ref = this.hover).update.apply(_ref, this.hoverContentForRow(index));
        return this.hilight(index);
      } else {
        this.hover.hide();
        return this.hilight();
      }
    };

    Line.prototype.hoverContentForRow = function (index) {
      var content, j, row, y, _i, _len, _ref;
      row = this.data[index];
      content = "<div class='morris-hover-row-label'>" + row.label + "</div>";
      _ref = row.y;
      for (j = _i = 0, _len = _ref.length; _i < _len; j = ++_i) {
        y = _ref[j];
        content +=
          "<div class='morris-hover-point' style='color: " +
          this.colorFor(row, j, "label") +
          "'>\n  " +
          this.options.labels[j] +
          ":\n  " +
          this.yLabelFormat(y) +
          "\n</div>";
      }
      if (typeof this.options.hoverCallback === "function") {
        content = this.options.hoverCallback(
          index,
          this.options,
          content,
          row.src
        );
      }
      return [content, row._x, row._ymax];
    };

    Line.prototype.generatePaths = function () {
      var coords, i, r, smooth;
      return (this.paths = function () {
        var _i, _ref, _ref1, _results;
        _results = [];
        for (
          i = _i = 0, _ref = this.options.ykeys.length;
          0 <= _ref ? _i < _ref : _i > _ref;
          i = 0 <= _ref ? ++_i : --_i
        ) {
          smooth =
            typeof this.options.smooth === "boolean"
              ? this.options.smooth
              : ((_ref1 = this.options.ykeys[i]),
                __indexOf.call(this.options.smooth, _ref1) >= 0);
          coords = function () {
            var _j, _len, _ref2, _results1;
            _ref2 = this.data;
            _results1 = [];
            for (_j = 0, _len = _ref2.length; _j < _len; _j++) {
              r = _ref2[_j];
              if (r._y[i] !== void 0) {
                _results1.push({
                  x: r._x,
                  y: r._y[i],
                });
              }
            }
            return _results1;
          }.call(this);
          if (coords.length > 1) {
            _results.push(Morris.Line.createPath(coords, smooth, this.bottom));
          } else {
            _results.push(null);
          }
        }
        return _results;
      }.call(this));
    };

    Line.prototype.draw = function () {
      var _ref;
      if (
        (_ref = this.options.axes) === true ||
        _ref === "both" ||
        _ref === "x"
      ) {
        this.drawXAxis();
      }
      this.drawSeries();
      if (this.options.hideHover === false) {
        return this.displayHoverForRow(this.data.length - 1);
      }
    };

    Line.prototype.drawXAxis = function () {
      var drawLabel,
        l,
        labels,
        prevAngleMargin,
        prevLabelMargin,
        row,
        ypos,
        _i,
        _len,
        _results,
        _this = this;
      ypos = this.bottom + this.options.padding / 2;
      prevLabelMargin = null;
      prevAngleMargin = null;
      drawLabel = function (labelText, xpos) {
        var label, labelBox, margin, offset, textBox;
        label = _this.drawXAxisLabel(_this.transX(xpos), ypos, labelText);
        textBox = label.getBBox();
        label.transform("r" + -_this.options.xLabelAngle);
        labelBox = label.getBBox();
        label.transform("t0," + labelBox.height / 2 + "...");
        if (_this.options.xLabelAngle !== 0) {
          offset =
            -0.5 *
            textBox.width *
            Math.cos((_this.options.xLabelAngle * Math.PI) / 180.0);
          label.transform("t" + offset + ",0...");
        }
        labelBox = label.getBBox();
        if (
          (prevLabelMargin == null ||
            prevLabelMargin >= labelBox.x + labelBox.width ||
            (prevAngleMargin != null && prevAngleMargin >= labelBox.x)) &&
          labelBox.x >= 0 &&
          labelBox.x + labelBox.width < _this.el.width()
        ) {
          if (_this.options.xLabelAngle !== 0) {
            margin =
              (1.25 * _this.options.gridTextSize) /
              Math.sin((_this.options.xLabelAngle * Math.PI) / 180.0);
            prevAngleMargin = labelBox.x - margin;
          }
          return (prevLabelMargin = labelBox.x - _this.options.xLabelMargin);
        } else {
          return label.remove();
        }
      };
      if (this.options.parseTime) {
        if (this.data.length === 1 && this.options.xLabels === "auto") {
          labels = [[this.data[0].label, this.data[0].x]];
        } else {
          labels = Morris.labelSeries(
            this.xmin,
            this.xmax,
            this.width,
            this.options.xLabels,
            this.options.xLabelFormat
          );
        }
      } else {
        labels = function () {
          var _i, _len, _ref, _results;
          _ref = this.data;
          _results = [];
          for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            row = _ref[_i];
            _results.push([row.label, row.x]);
          }
          return _results;
        }.call(this);
      }
      labels.reverse();
      _results = [];
      for (_i = 0, _len = labels.length; _i < _len; _i++) {
        l = labels[_i];
        _results.push(drawLabel(l[0], l[1]));
      }
      return _results;
    };

    Line.prototype.drawSeries = function () {
      var i, _i, _j, _ref, _ref1, _results;
      this.seriesPoints = [];
      for (
        i = _i = _ref = this.options.ykeys.length - 1;
        _ref <= 0 ? _i <= 0 : _i >= 0;
        i = _ref <= 0 ? ++_i : --_i
      ) {
        this._drawLineFor(i);
      }
      _results = [];
      for (
        i = _j = _ref1 = this.options.ykeys.length - 1;
        _ref1 <= 0 ? _j <= 0 : _j >= 0;
        i = _ref1 <= 0 ? ++_j : --_j
      ) {
        _results.push(this._drawPointFor(i));
      }
      return _results;
    };

    Line.prototype._drawPointFor = function (index) {
      var circle, row, _i, _len, _ref, _results;
      this.seriesPoints[index] = [];
      _ref = this.data;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        row = _ref[_i];
        circle = null;
        if (row._y[index] != null) {
          circle = this.drawLinePoint(
            row._x,
            row._y[index],
            this.colorFor(row, index, "point"),
            index
          );
        }
        _results.push(this.seriesPoints[index].push(circle));
      }
      return _results;
    };

    Line.prototype._drawLineFor = function (index) {
      var path;
      path = this.paths[index];
      if (path !== null) {
        return this.drawLinePath(
          path,
          this.colorFor(null, index, "line"),
          index
        );
      }
    };

    Line.createPath = function (coords, smooth, bottom) {
      var coord, g, grads, i, ix, lg, path, prevCoord, x1, x2, y1, y2, _i, _len;
      path = "";
      if (smooth) {
        grads = Morris.Line.gradients(coords);
      }
      prevCoord = {
        y: null,
      };
      for (i = _i = 0, _len = coords.length; _i < _len; i = ++_i) {
        coord = coords[i];
        if (coord.y != null) {
          if (prevCoord.y != null) {
            if (smooth) {
              g = grads[i];
              lg = grads[i - 1];
              ix = (coord.x - prevCoord.x) / 4;
              x1 = prevCoord.x + ix;
              y1 = Math.min(bottom, prevCoord.y + ix * lg);
              x2 = coord.x - ix;
              y2 = Math.min(bottom, coord.y - ix * g);
              path +=
                "C" +
                x1 +
                "," +
                y1 +
                "," +
                x2 +
                "," +
                y2 +
                "," +
                coord.x +
                "," +
                coord.y;
            } else {
              path += "L" + coord.x + "," + coord.y;
            }
          } else {
            if (!smooth || grads[i] != null) {
              path += "M" + coord.x + "," + coord.y;
            }
          }
        }
        prevCoord = coord;
      }
      return path;
    };

    Line.gradients = function (coords) {
      var coord, grad, i, nextCoord, prevCoord, _i, _len, _results;
      grad = function (a, b) {
        return (a.y - b.y) / (a.x - b.x);
      };
      _results = [];
      for (i = _i = 0, _len = coords.length; _i < _len; i = ++_i) {
        coord = coords[i];
        if (coord.y != null) {
          nextCoord = coords[i + 1] || {
            y: null,
          };
          prevCoord = coords[i - 1] || {
            y: null,
          };
          if (prevCoord.y != null && nextCoord.y != null) {
            _results.push(grad(prevCoord, nextCoord));
          } else if (prevCoord.y != null) {
            _results.push(grad(prevCoord, coord));
          } else if (nextCoord.y != null) {
            _results.push(grad(coord, nextCoord));
          } else {
            _results.push(null);
          }
        } else {
          _results.push(null);
        }
      }
      return _results;
    };

    Line.prototype.hilight = function (index) {
      var i, _i, _j, _ref, _ref1;
      if (this.prevHilight !== null && this.prevHilight !== index) {
        for (
          i = _i = 0, _ref = this.seriesPoints.length - 1;
          0 <= _ref ? _i <= _ref : _i >= _ref;
          i = 0 <= _ref ? ++_i : --_i
        ) {
          if (this.seriesPoints[i][this.prevHilight]) {
            this.seriesPoints[i][this.prevHilight].animate(
              this.pointShrinkSeries(i)
            );
          }
        }
      }
      if (index !== null && this.prevHilight !== index) {
        for (
          i = _j = 0, _ref1 = this.seriesPoints.length - 1;
          0 <= _ref1 ? _j <= _ref1 : _j >= _ref1;
          i = 0 <= _ref1 ? ++_j : --_j
        ) {
          if (this.seriesPoints[i][index]) {
            this.seriesPoints[i][index].animate(this.pointGrowSeries(i));
          }
        }
      }
      return (this.prevHilight = index);
    };

    Line.prototype.colorFor = function (row, sidx, type) {
      if (typeof this.options.lineColors === "function") {
        return this.options.lineColors.call(this, row, sidx, type);
      } else if (type === "point") {
        return (
          this.options.pointFillColors[
            sidx % this.options.pointFillColors.length
          ] || this.options.lineColors[sidx % this.options.lineColors.length]
        );
      } else {
        return this.options.lineColors[sidx % this.options.lineColors.length];
      }
    };

    Line.prototype.drawXAxisLabel = function (xPos, yPos, text) {
      return this.raphael
        .text(xPos, yPos, text)
        .attr("font-size", this.options.gridTextSize)
        .attr("font-family", this.options.gridTextFamily)
        .attr("font-weight", this.options.gridTextWeight)
        .attr("fill", this.options.gridTextColor);
    };

    Line.prototype.drawLinePath = function (path, lineColor, lineIndex) {
      return this.raphael
        .path(path)
        .attr("stroke", lineColor)
        .attr("stroke-width", this.lineWidthForSeries(lineIndex));
    };

    Line.prototype.drawLinePoint = function (
      xPos,
      yPos,
      pointColor,
      lineIndex
    ) {
      return this.raphael
        .circle(xPos, yPos, this.pointSizeForSeries(lineIndex))
        .attr("fill", pointColor)
        .attr("stroke-width", this.pointStrokeWidthForSeries(lineIndex))
        .attr("stroke", this.pointStrokeColorForSeries(lineIndex));
    };

    Line.prototype.pointStrokeWidthForSeries = function (index) {
      return this.options.pointStrokeWidths[
        index % this.options.pointStrokeWidths.length
      ];
    };

    Line.prototype.pointStrokeColorForSeries = function (index) {
      return this.options.pointStrokeColors[
        index % this.options.pointStrokeColors.length
      ];
    };

    Line.prototype.lineWidthForSeries = function (index) {
      if (this.options.lineWidth instanceof Array) {
        return this.options.lineWidth[index % this.options.lineWidth.length];
      } else {
        return this.options.lineWidth;
      }
    };

    Line.prototype.pointSizeForSeries = function (index) {
      if (this.options.pointSize instanceof Array) {
        return this.options.pointSize[index % this.options.pointSize.length];
      } else {
        return this.options.pointSize;
      }
    };

    Line.prototype.pointGrowSeries = function (index) {
      return Raphael.animation(
        {
          r: this.pointSizeForSeries(index) + 3,
        },
        25,
        "linear"
      );
    };

    Line.prototype.pointShrinkSeries = function (index) {
      return Raphael.animation(
        {
          r: this.pointSizeForSeries(index),
        },
        25,
        "linear"
      );
    };

    return Line;
  })(Morris.Grid);

  Morris.labelSeries = function (dmin, dmax, pxwidth, specName, xLabelFormat) {
    var d, d0, ddensity, name, ret, s, spec, t, _i, _len, _ref;
    ddensity = (200 * (dmax - dmin)) / pxwidth;
    d0 = new Date(dmin);
    spec = Morris.LABEL_SPECS[specName];
    if (spec === void 0) {
      _ref = Morris.AUTO_LABEL_ORDER;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        name = _ref[_i];
        s = Morris.LABEL_SPECS[name];
        if (ddensity >= s.span) {
          spec = s;
          break;
        }
      }
    }
    if (spec === void 0) {
      spec = Morris.LABEL_SPECS["second"];
    }
    if (xLabelFormat) {
      spec = $.extend({}, spec, {
        fmt: xLabelFormat,
      });
    }
    d = spec.start(d0);
    ret = [];
    while ((t = d.getTime()) <= dmax) {
      if (t >= dmin) {
        ret.push([spec.fmt(d), t]);
      }
      spec.incr(d);
    }
    return ret;
  };

  minutesSpecHelper = function (interval) {
    return {
      span: interval * 60 * 1000,
      start: function (d) {
        return new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours()
        );
      },
      fmt: function (d) {
        return (
          "" + Morris.pad2(d.getHours()) + ":" + Morris.pad2(d.getMinutes())
        );
      },
      incr: function (d) {
        return d.setUTCMinutes(d.getUTCMinutes() + interval);
      },
    };
  };

  secondsSpecHelper = function (interval) {
    return {
      span: interval * 1000,
      start: function (d) {
        return new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes()
        );
      },
      fmt: function (d) {
        return (
          "" +
          Morris.pad2(d.getHours()) +
          ":" +
          Morris.pad2(d.getMinutes()) +
          ":" +
          Morris.pad2(d.getSeconds())
        );
      },
      incr: function (d) {
        return d.setUTCSeconds(d.getUTCSeconds() + interval);
      },
    };
  };

  Morris.LABEL_SPECS = {
    decade: {
      span: 172800000000,
      start: function (d) {
        return new Date(d.getFullYear() - (d.getFullYear() % 10), 0, 1);
      },
      fmt: function (d) {
        return "" + d.getFullYear();
      },
      incr: function (d) {
        return d.setFullYear(d.getFullYear() + 10);
      },
    },
    year: {
      span: 17280000000,
      start: function (d) {
        return new Date(d.getFullYear(), 0, 1);
      },
      fmt: function (d) {
        return "" + d.getFullYear();
      },
      incr: function (d) {
        return d.setFullYear(d.getFullYear() + 1);
      },
    },
    month: {
      span: 2419200000,
      start: function (d) {
        return new Date(d.getFullYear(), d.getMonth(), 1);
      },
      fmt: function (d) {
        return "" + d.getFullYear() + "-" + Morris.pad2(d.getMonth() + 1);
      },
      incr: function (d) {
        return d.setMonth(d.getMonth() + 1);
      },
    },
    week: {
      span: 604800000,
      start: function (d) {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
      },
      fmt: function (d) {
        return (
          "" +
          d.getFullYear() +
          "-" +
          Morris.pad2(d.getMonth() + 1) +
          "-" +
          Morris.pad2(d.getDate())
        );
      },
      incr: function (d) {
        return d.setDate(d.getDate() + 7);
      },
    },
    day: {
      span: 86400000,
      start: function (d) {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
      },
      fmt: function (d) {
        return (
          "" +
          d.getFullYear() +
          "-" +
          Morris.pad2(d.getMonth() + 1) +
          "-" +
          Morris.pad2(d.getDate())
        );
      },
      incr: function (d) {
        return d.setDate(d.getDate() + 1);
      },
    },
    hour: minutesSpecHelper(60),
    "30min": minutesSpecHelper(30),
    "15min": minutesSpecHelper(15),
    "10min": minutesSpecHelper(10),
    "5min": minutesSpecHelper(5),
    minute: minutesSpecHelper(1),
    "30sec": secondsSpecHelper(30),
    "15sec": secondsSpecHelper(15),
    "10sec": secondsSpecHelper(10),
    "5sec": secondsSpecHelper(5),
    second: secondsSpecHelper(1),
  };

  Morris.AUTO_LABEL_ORDER = [
    "decade",
    "year",
    "month",
    "week",
    "day",
    "hour",
    "30min",
    "15min",
    "10min",
    "5min",
    "minute",
    "30sec",
    "15sec",
    "10sec",
    "5sec",
    "second",
  ];

  Morris.Area = (function (_super) {
    var areaDefaults;

    __extends(Area, _super);

    areaDefaults = {
      fillOpacity: "auto",
      behaveLikeLine: false,
    };

    function Area(options) {
      var areaOptions;
      if (!(this instanceof Morris.Area)) {
        return new Morris.Area(options);
      }
      areaOptions = $.extend({}, areaDefaults, options);
      this.cumulative = !areaOptions.behaveLikeLine;
      if (areaOptions.fillOpacity === "auto") {
        areaOptions.fillOpacity = areaOptions.behaveLikeLine ? 0.8 : 1;
      }
      Area.__super__.constructor.call(this, areaOptions);
    }

    Area.prototype.calcPoints = function () {
      var row, total, y, _i, _len, _ref, _results;
      _ref = this.data;
      _results = [];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        row = _ref[_i];
        row._x = this.transX(row.x);
        total = 0;
        row._y = function () {
          var _j, _len1, _ref1, _results1;
          _ref1 = row.y;
          _results1 = [];
          for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
            y = _ref1[_j];
            if (this.options.behaveLikeLine) {
              _results1.push(this.transY(y));
            } else {
              total += y || 0;
              _results1.push(this.transY(total));
            }
          }
          return _results1;
        }.call(this);
        _results.push((row._ymax = Math.max.apply(Math, row._y)));
      }
      return _results;
    };

    Area.prototype.drawSeries = function () {
      var i,
        range,
        _i,
        _j,
        _k,
        _len,
        _ref,
        _ref1,
        _results,
        _results1,
        _results2;
      this.seriesPoints = [];
      if (this.options.behaveLikeLine) {
        range = function () {
          _results = [];
          for (
            var _i = 0, _ref = this.options.ykeys.length - 1;
            0 <= _ref ? _i <= _ref : _i >= _ref;
            0 <= _ref ? _i++ : _i--
          ) {
            _results.push(_i);
          }
          return _results;
        }.apply(this);
      } else {
        range = function () {
          _results1 = [];
          for (
            var _j = (_ref1 = this.options.ykeys.length - 1);
            _ref1 <= 0 ? _j <= 0 : _j >= 0;
            _ref1 <= 0 ? _j++ : _j--
          ) {
            _results1.push(_j);
          }
          return _results1;
        }.apply(this);
      }
      _results2 = [];
      for (_k = 0, _len = range.length; _k < _len; _k++) {
        i = range[_k];
        this._drawFillFor(i);
        this._drawLineFor(i);
        _results2.push(this._drawPointFor(i));
      }
      return _results2;
    };

    Area.prototype._drawFillFor = function (index) {
      var path;
      path = this.paths[index];
      if (path !== null) {
        path =
          path +
          ("L" +
            this.transX(this.xmax) +
            "," +
            this.bottom +
            "L" +
            this.transX(this.xmin) +
            "," +
            this.bottom +
            "Z");
        return this.drawFilledPath(path, this.fillForSeries(index));
      }
    };

    Area.prototype.fillForSeries = function (i) {
      var color;
      color = Raphael.rgb2hsl(this.colorFor(this.data[i], i, "line"));
      return Raphael.hsl(
        color.h,
        this.options.behaveLikeLine ? color.s * 0.9 : color.s * 0.75,
        Math.min(
          0.98,
          this.options.behaveLikeLine ? color.l * 1.2 : color.l * 1.25
        )
      );
    };

    Area.prototype.drawFilledPath = function (path, fill) {
      return this.raphael
        .path(path)
        .attr("fill", fill)
        .attr("fill-opacity", this.options.fillOpacity)
        .attr("stroke", "none");
    };

    return Area;
  })(Morris.Line);

  Morris.Bar = (function (_super) {
    __extends(Bar, _super);

    function Bar(options) {
      this.onHoverOut = __bind(this.onHoverOut, this);
      this.onHoverMove = __bind(this.onHoverMove, this);
      this.onGridClick = __bind(this.onGridClick, this);
      if (!(this instanceof Morris.Bar)) {
        return new Morris.Bar(options);
      }
      Bar.__super__.constructor.call(
        this,
        $.extend({}, options, {
          parseTime: false,
        })
      );
    }

    Bar.prototype.init = function () {
      this.cumulative = this.options.stacked;
      if (this.options.hideHover !== "always") {
        this.hover = new Morris.Hover({
          parent: this.el,
        });
        this.on("hovermove", this.onHoverMove);
        this.on("hoverout", this.onHoverOut);
        return this.on("gridclick", this.onGridClick);
      }
    };

    Bar.prototype.defaults = {
      barSizeRatio: 0.75,
      barGap: 3,
      barColors: [
        "#0b62a4",
        "#7a92a3",
        "#4da74d",
        "#afd8f8",
        "#edc240",
        "#cb4b4b",
        "#9440ed",
      ],
      barOpacity: 1.0,
      barRadius: [0, 0, 0, 0],
      xLabelMargin: 50,
    };

    Bar.prototype.calc = function () {
      var _ref;
      this.calcBars();
      if (this.options.hideHover === false) {
        return (_ref = this.hover).update.apply(
          _ref,
          this.hoverContentForRow(this.data.length - 1)
        );
      }
    };

    Bar.prototype.calcBars = function () {
      var idx, row, y, _i, _len, _ref, _results;
      _ref = this.data;
      _results = [];
      for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
        row = _ref[idx];
        row._x = this.left + (this.width * (idx + 0.5)) / this.data.length;
        _results.push(
          (row._y = function () {
            var _j, _len1, _ref1, _results1;
            _ref1 = row.y;
            _results1 = [];
            for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
              y = _ref1[_j];
              if (y != null) {
                _results1.push(this.transY(y));
              } else {
                _results1.push(null);
              }
            }
            return _results1;
          }.call(this))
        );
      }
      return _results;
    };

    Bar.prototype.draw = function () {
      var _ref;
      if (
        (_ref = this.options.axes) === true ||
        _ref === "both" ||
        _ref === "x"
      ) {
        this.drawXAxis();
      }
      return this.drawSeries();
    };

    Bar.prototype.drawXAxis = function () {
      var i,
        label,
        labelBox,
        margin,
        offset,
        prevAngleMargin,
        prevLabelMargin,
        row,
        textBox,
        ypos,
        _i,
        _ref,
        _results;
      ypos =
        this.bottom +
        (this.options.xAxisLabelTopPadding || this.options.padding / 2);
      prevLabelMargin = null;
      prevAngleMargin = null;
      _results = [];
      for (
        i = _i = 0, _ref = this.data.length;
        0 <= _ref ? _i < _ref : _i > _ref;
        i = 0 <= _ref ? ++_i : --_i
      ) {
        row = this.data[this.data.length - 1 - i];
        label = this.drawXAxisLabel(row._x, ypos, row.label);
        textBox = label.getBBox();
        label.transform("r" + -this.options.xLabelAngle);
        labelBox = label.getBBox();
        label.transform("t0," + labelBox.height / 2 + "...");
        if (this.options.xLabelAngle !== 0) {
          offset =
            -0.5 *
            textBox.width *
            Math.cos((this.options.xLabelAngle * Math.PI) / 180.0);
          label.transform("t" + offset + ",0...");
        }
        if (
          (prevLabelMargin == null ||
            prevLabelMargin >= labelBox.x + labelBox.width ||
            (prevAngleMargin != null && prevAngleMargin >= labelBox.x)) &&
          labelBox.x >= 0 &&
          labelBox.x + labelBox.width < this.el.width()
        ) {
          if (this.options.xLabelAngle !== 0) {
            margin =
              (1.25 * this.options.gridTextSize) /
              Math.sin((this.options.xLabelAngle * Math.PI) / 180.0);
            prevAngleMargin = labelBox.x - margin;
          }
          _results.push(
            (prevLabelMargin = labelBox.x - this.options.xLabelMargin)
          );
        } else {
          _results.push(label.remove());
        }
      }
      return _results;
    };

    Bar.prototype.drawSeries = function () {
      var barWidth,
        bottom,
        groupWidth,
        idx,
        lastTop,
        left,
        leftPadding,
        numBars,
        row,
        sidx,
        size,
        spaceLeft,
        top,
        ypos,
        zeroPos;
      groupWidth = this.width / this.options.data.length;
      numBars = this.options.stacked ? 1 : this.options.ykeys.length;
      barWidth =
        (groupWidth * this.options.barSizeRatio -
          this.options.barGap * (numBars - 1)) /
        numBars;
      if (this.options.barSize) {
        barWidth = Math.min(barWidth, this.options.barSize);
      }
      spaceLeft =
        groupWidth - barWidth * numBars - this.options.barGap * (numBars - 1);
      leftPadding = spaceLeft / 2;
      zeroPos = this.ymin <= 0 && this.ymax >= 0 ? this.transY(0) : null;
      return (this.bars = function () {
        var _i, _len, _ref, _results;
        _ref = this.data;
        _results = [];
        for (idx = _i = 0, _len = _ref.length; _i < _len; idx = ++_i) {
          row = _ref[idx];
          lastTop = 0;
          _results.push(
            function () {
              var _j, _len1, _ref1, _results1;
              _ref1 = row._y;
              _results1 = [];
              for (
                sidx = _j = 0, _len1 = _ref1.length;
                _j < _len1;
                sidx = ++_j
              ) {
                ypos = _ref1[sidx];
                if (ypos !== null) {
                  if (zeroPos) {
                    top = Math.min(ypos, zeroPos);
                    bottom = Math.max(ypos, zeroPos);
                  } else {
                    top = ypos;
                    bottom = this.bottom;
                  }
                  left = this.left + idx * groupWidth + leftPadding;
                  if (!this.options.stacked) {
                    left += sidx * (barWidth + this.options.barGap);
                  }
                  size = bottom - top;
                  if (
                    this.options.verticalGridCondition &&
                    this.options.verticalGridCondition(row.x)
                  ) {
                    this.drawBar(
                      this.left + idx * groupWidth,
                      this.top,
                      groupWidth,
                      Math.abs(this.top - this.bottom),
                      this.options.verticalGridColor,
                      this.options.verticalGridOpacity,
                      this.options.barRadius
                    );
                  }
                  if (this.options.stacked) {
                    top -= lastTop;
                  }
                  this.drawBar(
                    left,
                    top,
                    barWidth,
                    size,
                    this.colorFor(row, sidx, "bar"),
                    this.options.barOpacity,
                    this.options.barRadius
                  );
                  _results1.push((lastTop += size));
                } else {
                  _results1.push(null);
                }
              }
              return _results1;
            }.call(this)
          );
        }
        return _results;
      }.call(this));
    };

    Bar.prototype.colorFor = function (row, sidx, type) {
      var r, s;
      if (typeof this.options.barColors === "function") {
        r = {
          x: row.x,
          y: row.y[sidx],
          label: row.label,
        };
        s = {
          index: sidx,
          key: this.options.ykeys[sidx],
          label: this.options.labels[sidx],
        };
        return this.options.barColors.call(this, r, s, type);
      } else {
        return this.options.barColors[sidx % this.options.barColors.length];
      }
    };

    Bar.prototype.hitTest = function (x) {
      if (this.data.length === 0) {
        return null;
      }
      x = Math.max(Math.min(x, this.right), this.left);
      return Math.min(
        this.data.length - 1,
        Math.floor((x - this.left) / (this.width / this.data.length))
      );
    };

    Bar.prototype.onGridClick = function (x, y) {
      var index;
      index = this.hitTest(x);
      return this.fire("click", index, this.data[index].src, x, y);
    };

    Bar.prototype.onHoverMove = function (x, y) {
      var index, _ref;
      index = this.hitTest(x);
      return (_ref = this.hover).update.apply(
        _ref,
        this.hoverContentForRow(index)
      );
    };

    Bar.prototype.onHoverOut = function () {
      if (this.options.hideHover !== false) {
        return this.hover.hide();
      }
    };

    Bar.prototype.hoverContentForRow = function (index) {
      var content, j, row, x, y, _i, _len, _ref;
      row = this.data[index];
      content = "<div class='morris-hover-row-label'>" + row.label + "</div>";
      _ref = row.y;
      for (j = _i = 0, _len = _ref.length; _i < _len; j = ++_i) {
        y = _ref[j];
        content +=
          "<div class='morris-hover-point' style='color: " +
          this.colorFor(row, j, "label") +
          "'>\n  " +
          this.options.labels[j] +
          ":\n  " +
          this.yLabelFormat(y) +
          "\n</div>";
      }
      if (typeof this.options.hoverCallback === "function") {
        content = this.options.hoverCallback(
          index,
          this.options,
          content,
          row.src
        );
      }
      x = this.left + ((index + 0.5) * this.width) / this.data.length;
      return [content, x];
    };

    Bar.prototype.drawXAxisLabel = function (xPos, yPos, text) {
      var label;
      return (label = this.raphael
        .text(xPos, yPos, text)
        .attr("font-size", this.options.gridTextSize)
        .attr("font-family", this.options.gridTextFamily)
        .attr("font-weight", this.options.gridTextWeight)
        .attr("fill", this.options.gridTextColor));
    };

    Bar.prototype.drawBar = function (
      xPos,
      yPos,
      width,
      height,
      barColor,
      opacity,
      radiusArray
    ) {
      var maxRadius, path;
      maxRadius = Math.max.apply(Math, radiusArray);
      if (maxRadius === 0 || maxRadius > height) {
        path = this.raphael.rect(xPos, yPos, width, height);
      } else {
        path = this.raphael.path(
          this.roundedRect(xPos, yPos, width, height, radiusArray)
        );
      }
      return path
        .attr("fill", barColor)
        .attr("fill-opacity", opacity)
        .attr("stroke", "none");
    };

    Bar.prototype.roundedRect = function (x, y, w, h, r) {
      if (r == null) {
        r = [0, 0, 0, 0];
      }
      return [
        "M",
        x,
        r[0] + y,
        "Q",
        x,
        y,
        x + r[0],
        y,
        "L",
        x + w - r[1],
        y,
        "Q",
        x + w,
        y,
        x + w,
        y + r[1],
        "L",
        x + w,
        y + h - r[2],
        "Q",
        x + w,
        y + h,
        x + w - r[2],
        y + h,
        "L",
        x + r[3],
        y + h,
        "Q",
        x,
        y + h,
        x,
        y + h - r[3],
        "Z",
      ];
    };

    return Bar;
  })(Morris.Grid);

  Morris.Donut = (function (_super) {
    __extends(Donut, _super);

    Donut.prototype.defaults = {
      colors: [
        "#0B62A4",
        "#3980B5",
        "#679DC6",
        "#95BBD7",
        "#B0CCE1",
        "#095791",
        "#095085",
        "#083E67",
        "#052C48",
        "#042135",
      ],
      backgroundColor: "#FFFFFF",
      labelColor: "#000000",
      formatter: Morris.commas,
      resize: false,
    };

    function Donut(options) {
      this.resizeHandler = __bind(this.resizeHandler, this);
      this.select = __bind(this.select, this);
      this.click = __bind(this.click, this);
      var _this = this;
      if (!(this instanceof Morris.Donut)) {
        return new Morris.Donut(options);
      }
      this.options = $.extend({}, this.defaults, options);
      if (typeof options.element === "string") {
        this.el = $(document.getElementById(options.element));
      } else {
        this.el = $(options.element);
      }
      if (this.el === null || this.el.length === 0) {
        throw new Error("Graph placeholder not found.");
      }
      if (options.data === void 0 || options.data.length === 0) {
        return;
      }
      this.raphael = new Raphael(this.el[0]);
      if (this.options.resize) {
        $(window).bind("resize", function (evt) {
          if (_this.timeoutId != null) {
            window.clearTimeout(_this.timeoutId);
          }
          return (_this.timeoutId = window.setTimeout(
            _this.resizeHandler,
            100
          ));
        });
      }
      this.setData(options.data);
    }

    Donut.prototype.redraw = function () {
      var C,
        cx,
        cy,
        i,
        idx,
        last,
        max_value,
        min,
        next,
        seg,
        total,
        value,
        w,
        _i,
        _j,
        _k,
        _len,
        _len1,
        _len2,
        _ref,
        _ref1,
        _ref2,
        _results;
      this.raphael.clear();
      cx = this.el.width() / 2;
      cy = this.el.height() / 2;
      w = (Math.min(cx, cy) - 10) / 3;
      total = 0;
      _ref = this.values;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        value = _ref[_i];
        total += value;
      }
      min = 5 / (2 * w);
      C = 1.9999 * Math.PI - min * this.data.length;
      last = 0;
      idx = 0;
      this.segments = [];
      _ref1 = this.values;
      for (i = _j = 0, _len1 = _ref1.length; _j < _len1; i = ++_j) {
        value = _ref1[i];
        next = last + min + C * (value / total);
        seg = new Morris.DonutSegment(
          cx,
          cy,
          w * 2,
          w,
          last,
          next,
          this.data[i].color ||
            this.options.colors[idx % this.options.colors.length],
          this.options.backgroundColor,
          idx,
          this.raphael
        );
        seg.render();
        this.segments.push(seg);
        seg.on("hover", this.select);
        seg.on("click", this.click);
        last = next;
        idx += 1;
      }
      this.text1 = this.drawEmptyDonutLabel(
        cx,
        cy - 10,
        this.options.labelColor,
        15,
        800
      );
      this.text2 = this.drawEmptyDonutLabel(
        cx,
        cy + 10,
        this.options.labelColor,
        14
      );
      max_value = Math.max.apply(Math, this.values);
      idx = 0;
      _ref2 = this.values;
      _results = [];
      for (_k = 0, _len2 = _ref2.length; _k < _len2; _k++) {
        value = _ref2[_k];
        if (value === max_value) {
          this.select(idx);
          break;
        }
        _results.push((idx += 1));
      }
      return _results;
    };

    Donut.prototype.setData = function (data) {
      var row;
      this.data = data;
      this.values = function () {
        var _i, _len, _ref, _results;
        _ref = this.data;
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
          row = _ref[_i];
          _results.push(parseFloat(row.value));
        }
        return _results;
      }.call(this);
      return this.redraw();
    };

    Donut.prototype.click = function (idx) {
      return this.fire("click", idx, this.data[idx]);
    };

    Donut.prototype.select = function (idx) {
      var row, s, segment, _i, _len, _ref;
      _ref = this.segments;
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        s = _ref[_i];
        s.deselect();
      }
      segment = this.segments[idx];
      segment.select();
      row = this.data[idx];
      return this.setLabels(row.label, this.options.formatter(row.value, row));
    };

    Donut.prototype.setLabels = function (label1, label2) {
      var inner,
        maxHeightBottom,
        maxHeightTop,
        maxWidth,
        text1bbox,
        text1scale,
        text2bbox,
        text2scale;
      inner =
        ((Math.min(this.el.width() / 2, this.el.height() / 2) - 10) * 2) / 3;
      maxWidth = 1.8 * inner;
      maxHeightTop = inner / 2;
      maxHeightBottom = inner / 3;
      this.text1.attr({
        text: label1,
        transform: "",
      });
      text1bbox = this.text1.getBBox();
      text1scale = Math.min(
        maxWidth / text1bbox.width,
        maxHeightTop / text1bbox.height
      );
      this.text1.attr({
        transform:
          "S" +
          text1scale +
          "," +
          text1scale +
          "," +
          (text1bbox.x + text1bbox.width / 2) +
          "," +
          (text1bbox.y + text1bbox.height),
      });
      this.text2.attr({
        text: label2,
        transform: "",
      });
      text2bbox = this.text2.getBBox();
      text2scale = Math.min(
        maxWidth / text2bbox.width,
        maxHeightBottom / text2bbox.height
      );
      return this.text2.attr({
        transform:
          "S" +
          text2scale +
          "," +
          text2scale +
          "," +
          (text2bbox.x + text2bbox.width / 2) +
          "," +
          text2bbox.y,
      });
    };

    Donut.prototype.drawEmptyDonutLabel = function (
      xPos,
      yPos,
      color,
      fontSize,
      fontWeight
    ) {
      var text;
      text = this.raphael
        .text(xPos, yPos, "")
        .attr("font-size", fontSize)
        .attr("fill", color);
      if (fontWeight != null) {
        text.attr("font-weight", fontWeight);
      }
      return text;
    };

    Donut.prototype.resizeHandler = function () {
      this.timeoutId = null;
      this.raphael.setSize(this.el.width(), this.el.height());
      return this.redraw();
    };

    return Donut;
  })(Morris.EventEmitter);

  Morris.DonutSegment = (function (_super) {
    __extends(DonutSegment, _super);

    function DonutSegment(
      cx,
      cy,
      inner,
      outer,
      p0,
      p1,
      color,
      backgroundColor,
      index,
      raphael
    ) {
      this.cx = cx;
      this.cy = cy;
      this.inner = inner;
      this.outer = outer;
      this.color = color;
      this.backgroundColor = backgroundColor;
      this.index = index;
      this.raphael = raphael;
      this.deselect = __bind(this.deselect, this);
      this.select = __bind(this.select, this);
      this.sin_p0 = Math.sin(p0);
      this.cos_p0 = Math.cos(p0);
      this.sin_p1 = Math.sin(p1);
      this.cos_p1 = Math.cos(p1);
      this.is_long = p1 - p0 > Math.PI ? 1 : 0;
      this.path = this.calcSegment(this.inner + 3, this.inner + this.outer - 5);
      this.selectedPath = this.calcSegment(
        this.inner + 3,
        this.inner + this.outer
      );
      this.hilight = this.calcArc(this.inner);
    }

    DonutSegment.prototype.calcArcPoints = function (r) {
      return [
        this.cx + r * this.sin_p0,
        this.cy + r * this.cos_p0,
        this.cx + r * this.sin_p1,
        this.cy + r * this.cos_p1,
      ];
    };

    DonutSegment.prototype.calcSegment = function (r1, r2) {
      var ix0, ix1, iy0, iy1, ox0, ox1, oy0, oy1, _ref, _ref1;
      (_ref = this.calcArcPoints(r1)),
        (ix0 = _ref[0]),
        (iy0 = _ref[1]),
        (ix1 = _ref[2]),
        (iy1 = _ref[3]);
      (_ref1 = this.calcArcPoints(r2)),
        (ox0 = _ref1[0]),
        (oy0 = _ref1[1]),
        (ox1 = _ref1[2]),
        (oy1 = _ref1[3]);
      return (
        "M" +
        ix0 +
        "," +
        iy0 +
        ("A" + r1 + "," + r1 + ",0," + this.is_long + ",0," + ix1 + "," + iy1) +
        ("L" + ox1 + "," + oy1) +
        ("A" + r2 + "," + r2 + ",0," + this.is_long + ",1," + ox0 + "," + oy0) +
        "Z"
      );
    };

    DonutSegment.prototype.calcArc = function (r) {
      var ix0, ix1, iy0, iy1, _ref;
      (_ref = this.calcArcPoints(r)),
        (ix0 = _ref[0]),
        (iy0 = _ref[1]),
        (ix1 = _ref[2]),
        (iy1 = _ref[3]);
      return (
        "M" +
        ix0 +
        "," +
        iy0 +
        ("A" + r + "," + r + ",0," + this.is_long + ",0," + ix1 + "," + iy1)
      );
    };

    DonutSegment.prototype.render = function () {
      var _this = this;
      this.arc = this.drawDonutArc(this.hilight, this.color);
      return (this.seg = this.drawDonutSegment(
        this.path,
        this.color,
        this.backgroundColor,
        function () {
          return _this.fire("hover", _this.index);
        },
        function () {
          return _this.fire("click", _this.index);
        }
      ));
    };

    DonutSegment.prototype.drawDonutArc = function (path, color) {
      return this.raphael.path(path).attr({
        stroke: color,
        "stroke-width": 2,
        opacity: 0,
      });
    };

    DonutSegment.prototype.drawDonutSegment = function (
      path,
      fillColor,
      strokeColor,
      hoverFunction,
      clickFunction
    ) {
      return this.raphael
        .path(path)
        .attr({
          fill: fillColor,
          stroke: strokeColor,
          "stroke-width": 3,
        })
        .hover(hoverFunction)
        .click(clickFunction);
    };

    DonutSegment.prototype.select = function () {
      if (!this.selected) {
        this.seg.animate(
          {
            path: this.selectedPath,
          },
          150,
          "<>"
        );
        this.arc.animate(
          {
            opacity: 1,
          },
          150,
          "<>"
        );
        return (this.selected = true);
      }
    };

    DonutSegment.prototype.deselect = function () {
      if (this.selected) {
        this.seg.animate(
          {
            path: this.path,
          },
          150,
          "<>"
        );
        this.arc.animate(
          {
            opacity: 0,
          },
          150,
          "<>"
        );
        return (this.selected = false);
      }
    };

    return DonutSegment;
  })(Morris.EventEmitter);
}.call(this));
