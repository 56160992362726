export const baseURL = "https://mova.codderlab.com/";
// export const baseURL = "http://192.168.29.183:5008/";

export const secretKey = "eTVt66nXHXm9cpeFff3G9ILE3ja4ILCn";

export const projectName = "Mova";
export const folderStructurePath = "mova/";
export const bucketName = "codderlab";
export const hostname = "https://blr1.digitaloceanspaces.com/";
export const aws_access_key_id = "DO00W6HQVBUR4T8FFXAL";
export const aws_secret_access_key =
  "1IIaXFh6Znyz1Ryu87lZXOZckV4m5jXlzOZzUrIShT4";
