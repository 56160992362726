// get advertise
export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";

// update  advertise
export const UPDATE_ADVERTISE = "UPDATE_ADVERTISE";

//active deActive advertise
export const ACTIVE_ADVERTISE = "ACTIVE_ADVERTISE";

//toast
export const OPEN_ADS_TOAST = "OPEN_ADS_TOAST";
export const CLOSE_ADS_TOAST = "CLOSE_ADS_TOAST";
