export const GET_LIVE_TV = "GET_LIVE_TV";

export const GET_LIVE_TV_CREATE_BY_ADMIN = "GET_LIVE_TV_CREATE_BY_ADMIN"
// create live tv

export const CREATE_LIVE_TV = "CREATE_LIVE_TV";

//  open dialogue

export const OPEN_LIVE_TV_DIALOGUE = "OPEN_LIVE_DIALOGUE";

// close dialogue
export const CLOSE_LIVE_TV_DIALOGUE = "CLOSE_LIVE_TV_DIALOGUE";




// get country

export const GET_COUNTRY = "GET_COUNTRY";

// edit liveTv channel  

export const EDIT_LIVETV_CHANNEL = "EDIT_LIVETV_CHANNEL";


// delete liveTv channel

export const DELETE_LIVETV_CHANNEL = "DELETE_LIVETV_CHANNEL"


export const GET_FLAG ="GET_FLAG"
